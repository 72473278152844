<template>
  <v-dialog width="60%" @click:outside="closeDialog" v-model="getPdfViewer.show" class="overflow-x-hidden">
    <v-card class="pa-2">
      <v-row no-gutters>
        <v-col cols="11"><v-card-title class="justify-center headline">
            {{ getPdfViewer.name }} {{ getPdfViewer.type }}
          </v-card-title>
        </v-col>
        <v-col cols="1">
          <!-- <div style="position: absolute; align-self: end; right: 0"> -->
            <v-btn icon @click="closeDialog" class="mr-5 mt-3">
              <v-icon color="red" x-large>mdi-window-close</v-icon>
            </v-btn>
          <!-- </div> -->
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="1" align-self="center">
          <v-icon v-if="currentPdf !== 0" @click="pdfCycle('previous')" color="primary" size="80">
            mdi-chevron-left-box
          </v-icon>
        </v-col>
        <v-col align="center" cols="10">
          <div class="scroll" style="height: 570px;overflow-y: scroll;overflow-x: hidden">
            <pdf v-for="i in numOfPages" :key="i" :page="i" :src="pdfUrl" ref="pdf" width="100%" @error="errorOccured"
              class="">
              <canvas></canvas>
            </pdf>
          </div>
          {{ currentPdf + 1 }}/{{ numOfPdf }}
        </v-col>
        <v-col cols="1" align-self="center">
          <v-icon v-if="currentPdf !== numOfPdf - 1" @click="pdfCycle('next')" color="primary" size="80">
            mdi-chevron-right-box
          </v-icon>
        </v-col>
        <v-col cols="12" justify="end" align="end">
          <v-btn width="350" color="primary" x-large class="ma-2 text-h5" @click="pickUp()">
            {{ $t('pickupButton') }}
          </v-btn>
          <v-btn width="350" color="primary" x-large class="ma-2 text-h5" @click="sendEmail()">
            {{ $t('emailButton') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import pdf from 'vue-pdf'
import { mapGetters } from 'vuex'
let config = require('./../../../config/config')

export default {
  name: 'pdfViewer',
  data() {
    return {
      loadingTask: null,
      numOfPages: null,
      pdfUrl: null,
      numOfPdf: 1,
      currentPdf: 0,
    }
  },
  components: {
    pdf
  },
  computed: {
    ...mapGetters(['getPdfViewer'])
  },
  methods: {
    viewPdf(url) {
      this.pdfUrl = url;
      this.loadingTask = pdf.createLoadingTask(url)
      this.loadingTask.promise
        .then(pdf => {
          this.numOfPages = pdf.numPages;
        })
    },
    pdfCycle(direction) {
      if (direction === 'next') {
        if (this.currentPdf === this.numOfPdf - 1) this.currentPdf = 0;
        this.viewPdf(this.getPdfViewer.url[++this.currentPdf])
      } else {
        if (this.currentPdf === 0) this.currentPdf = this.numOfPdf - 1;
        this.viewPdf(this.getPdfViewer.url[--this.currentPdf])

      }

    },
    closeDialog() {
      this.numOfPages = null;
      this.numOfPdf = 1;
      this.pdfUrl = null;
      this.currentPdf = 0;
      this.$store.commit('closePdfViewer');
      window.stopSpeech();
    },
    errorOccured() {
      console.log('Error while loading pdf')
    },
    pickUp() {
      this.$store.dispatch('openImageViewer', {
        url: config.url.clerkOffice
      })
      this.$store.commit('closePdfViewer')
      this.$store.dispatch('avatarSpeak', 'Pick up this form for a fee at the Clerk’s office, circled in red.')
    },
    sendEmail() {
      this.$store.dispatch('openEmailViewer', {
        pdfType: this.getPdfViewer.type,
        pdfName: this.getPdfViewer.name,
        pdfUrl: this.getPdfViewer.emailLink
      })
      this.$store.commit('closePdfViewer')
    }
  },
  watch: {
    getPdfViewer(newVal) {
      if (newVal.show) {
        let speech = '';
        switch (this.getPdfViewer.type.toLowerCase()) {
          case 'form':
            this.viewPdf(this.getPdfViewer.url)
            speech = this.$i18n.t("formText");
            break;
          case 'packet':
            this.viewPdf(this.getPdfViewer.url[this.currentPdf])
            this.numOfPdf = this.getPdfViewer.url.length;
            speech = this.$i18n.t("packetText")
            break;
          case 'guide':
            this.viewPdf(this.getPdfViewer.url)
            speech = this.$i18n.t("guideText");
            break;
          default:
            break;
        }
        this.$store.dispatch('avatarSpeak', speech)
      }
    }
  }
}
</script>
<style>
.scroll::-webkit-scrollbar {
  -webkit-appearance: none;
  color: #d37444 !important;
  width: 10px;
}

.scroll::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px #d37444 !important;
}
</style>
<i18n>
  {
    "en": {
        "formText": "You can pick-up this form for a fee, or I'll email you the form for free. Please select your choice.",
        "packetText": "You can pick-up this packet for a fee, or I'll email you the packet for free. Please select your choice.",
        "guideText": "You can pick-up this guide for a fee, or I'll email you the guide for free. Please select your choice.",
        "pickupText": "Pick up this form for a fee at the Clerk's office, circled in red.",
        "emailButton": "Email",
        "pickupButton": "Pick Up"
      },
    "es":{
        "formText": "Puede escoger este formulario si paga un cargo, o se lo puedo enviar gratis a su correo electrónico. Seleccione su opción.",
        "packetText": "Puede escoger este paquete si paga un cargo, o se lo puedo enviar gratis a su correo electrónico. Seleccione su opción.",
        "guideText": "Puede escoger esta guía si paga un cargo, o se la puedo enviar gratis a su correo electrónico. Seleccione su opción.",
        "pickupText": "Escoja este formulario si paga un cargo en la oficina del secretario, está marcado con un círculo rojo.",
        "emailButton": "Correo Electrónico",
        "pickupButton": "Pasar a Buscar"
      }
  }
</i18n>