let request_as_promised = require('request-promise');
let config = require('./../../../config/config')
import router from "@/router";
import i18n from "../../i18n";

export default {
    state: {
        qnaStatus: null,
        qnaKeyword: '',
        wrongAnswerCount: 0,
        court: ''
    },
    getters: {
        getQnaStatus: state => state.qnaStatus,
        getQnaKeyword: state => state.qnaKeyword,
        getWrongAnsCount: state => state.wrongAnswerCount,
        getCourt: state => state.court
    },
    mutations: {
        setQnaStatus(state, status) {
            state.qnaStatus = status;
        },
        setQnaKeyword(state, keyword) {
            state.qnaKeyword = keyword
        },
        setWrongAnswerCount(state, count) {
            state.wrongAnswerCount = count
        },
        setCourt(state, court) {
            state.court = court
        }
    },
    actions: {
        requestQnA({ getters, dispatch, commit }, transcript) {
            return new Promise((response, reject) => {
                console.log('Is request QnA called')
                commit('setQnaStatus', 'Thinking')

                let question = {
                    'question': transcript,
                    'top': 3,
                    'scoreThreshold': 10,
                    'rankerType': 'QuestionOnly'
                };
                if (typeof transcript === 'object') {
                    question = {
                        'question': transcript.displayText,
                        'top': 3,
                        'scoreThreshold': 10,
                        'rankerType': 'QuestionOnly',
                        'qnaId': transcript.qnaId
                    };
                    console.log("QUESTION:", question)
                }
                let options = {
                    uri: getters.getAzureHost + getters.getAzureRoute,
                    method: 'POST',
                    headers: {
                        'Authorization': "EndpointKey " + getters.getAzureEndpointKey
                    },
                    json: true,
                    body: question
                };

                request_as_promised.post(options)
                    .then(result => {
                        const qnaAnalytics = {
                            timeStamp: new Date(),
                            kioskID: getters.getKioskId,
                            transcript: transcript,
                            result: result,
                        }
                        dispatch('sendQnaData', qnaAnalytics);
                        if (result.answers[0].score > 60) {
                            dispatch('generateAnswer', result)
                            commit('setTouchRequest', {
                                module: 'QnA',
                                action: transcript,
                                response: result.answers[0].answer,
                                timeStamp: new Date(),
                                requestType: 'Speech'
                            })
                        } else if (result.answers[0].score > 45) {
                            dispatch('avatarSpeak', 'I didn\'t quite get that, did you mean to ask following questions. Please tap your preference.')
                            let followUp = [];

                            commit('setTouchRequest', {
                                module: 'QnA',
                                action: transcript,
                                response: 'Follow Up question',
                                timeStamp: new Date(),
                                requestType: 'Speech'
                            })

                            result.answers.forEach(output => {
                                if (output.score > 45)
                                    followUp.push(output.questions[0])
                            });
                            dispatch('openOptionsViewer', {
                                dialogHeader: 'Please Tap the option you prefer',
                                dialogData: followUp,
                            })
                        } else if (result.answers[0].id === -1) {
                            dispatch('openImageViewer', {
                                url: config.url.clerkOffice
                            })

                            commit('setTouchRequest', {
                                module: 'QnA',
                                action: transcript,
                                response: 'Not Answered -  Add Action to KB',
                                timeStamp: new Date(),
                                requestType: 'Speech'
                            })

                            dispatch('avatarSpeak', 'Sorry, I\'m not sure how to answer this question. Please ask me a different question, or you can continue using the touch interface. Also, you can get more information at the Clerk’s Office located here.')
                        } else {

                            commit('setTouchRequest', {
                                module: 'QnA',
                                action: transcript,
                                response: 'Not Answered -  Add Action to KB',
                                timeStamp: new Date(),
                                requestType: 'Speech'
                            })


                            if (getters.getWrongAnsCount === 0) {
                                commit('setWrongAnswerCount', getters.getWrongAnsCount + 1);
                                dispatch('avatarSpeak', 'Sorry! I just started working here, I will soon know answers to all the court related questions. Please try asking different question or visit Clerk\'s office.')
                            } else if (getters.getWrongAnsCount === 1) {
                                commit('setWrongAnswerCount', getters.getWrongAnsCount + 1);
                                dispatch('avatarSpeak', 'Sorry! I don\'t know the answer to this. I will make sure it will be there next time you ask. For now, please try asking different question.')
                            } else if (getters.getWrongAnsCount === 2) {
                                commit('setWrongAnswerCount', 0);
                                dispatch('openImageViewer', {
                                    url: config.url.clerkOffice
                                })
                                dispatch('avatarSpeak', 'Sorry I’m still learning. Please ask me a different question, or you can continue using the touch interface. Also, you can get more information at the Clerk’s Office located here.')
                            }
                        }

                    })
            })
        },
        generateAnswer({ dispatch, commit, getters }, result) {
            commit('setQnaStatus', false)
            const metadata = result.answers[0].metadata.find(({ name }) => name === 'intent').value
            const ansObj = result.answers[0]
            commit('setDefaultBubbleText', false)
            commit('closeOptionsViewer')
            switch (metadata) {
                case "navigate":
                    var menuName = ansObj.metadata.find(({ name }) => name === 'navigateto').value;
                    router.addRoute({ path: menuName.toLowerCase(), component: () => import('@/views/' + menuName) });
                    if (router.currentRoute.path !== menuName) {
                        router.push(menuName).then(() => {
                            commit('setQnaKeyword', '')
                            dispatch('avatarSpeak', ansObj.answer);
                            let page = i18n.t('homepages').filter(page => page.pageType[0].pathName === menuName)
                            commit('setPageHeader', page[0].pageType[0].pageHeader)
                        })
                    } else {
                        commit('setQnaKeyword', '')
                        dispatch('avatarSpeak', ansObj.answer)
                    }
                    break;

                case "showmap":

                    try {
                        var mapPath = i18n.t('maps').filter(map => map.mapName.toLowerCase() === ansObj.metadata.find(({ name }) => name === 'mappath').value)[0]
                        //var mapPath = i18n.t('maps').get(ansObj.metadata.find(({ name }) => name === 'mappath').value)
                        dispatch('openImageViewer', {
                            url: mapPath.mapImage
                        })
                        dispatch('avatarSpeak', ansObj.answer);
                    } catch (e) {
                        console.log(e.message)
                        dispatch('openImageViewer', {
                            url: config.url.clerkOffice
                        })
                        dispatch('avatarSpeak', 'Sorry, I\'m not sure how to answer this question. Please ask me a different question, or you can continue using the touch interface. Also, you can get more information at the Clerk’s Office located here.')
                    }
                    break;

                case "speak":

                    dispatch('avatarSpeak', ansObj.answer);
                    break;

                case "showform":
                    try {
                        //var formPath;
                        //var formPath = getters.getForms.get(ansObj.metadata.find(({name}) => name.toLowerCase() === 'formname').value)
                        var formName = ansObj.metadata.find(({ name }) => name === 'formname')
                        i18n.t('forms').forEach(form => {
                            if (form.formNumber.toLowerCase() === formName.value) {
                                dispatch('openPdfViewer', {
                                    url: form.formPdf,
                                    name: form.formName,
                                    emailLink: form.pdfUrl,
                                    type: 'Form'
                                })
                                dispatch('avatarSpeak', ansObj.answer);
                            }
                        })
                    } catch (e) {
                        console.log(e.message)
                        dispatch('openImageViewer', {
                            url: config.url.clerkOffice
                        })
                        dispatch('avatarSpeak', 'Sorry! I just started working here, I will soon know answers to all the court related questions. Pleas try asking different question or visit Clerk\'s office.');
                    }
                    break;

                case "showfaqcategory":

                    var faqKeyword = ansObj.metadata.find(({ name }) => name === 'keyword').value

                    router.addRoute({ path: '/faqs', component: () => import('@/views/faqs') })
                    if (router.currentRoute.path !== '/faqs') {
                        router.push('/faqs')
                    }
                    commit('setPageHeader', 'Frequently Asked Questions')
                    commit('setSelectedFaqCat', { name: faqKeyword, type: 'expansionPanel' })
                    dispatch('avatarSpeak', ansObj.answer);
                    break;

                case "showformcategory":

                    var formKeyword = ansObj.metadata.find(({ name }) => name === 'keyword').value
                    router.addRoute({ path: '/forms', component: () => import('@/views/forms') })
                    if (router.currentRoute.path !== '/forms') {
                        router.push('/forms')
                    }
                    commit('setPageHeader', 'Forms')
                    commit('setSelectedFormCat', { category: formKeyword, showTab: true })
                    dispatch('avatarSpeak', ansObj.answer);
                    break;

                case "showformpackets":

                    var packetInfo = getters.getFormPackets.get(ansObj.metadata.find(({ name }) => name === 'keyword').value)
                    dispatch('openPdfViewer', {
                        url: packetInfo.packetFiles,
                        name: packetInfo.packetName,
                        desc: packetInfo.packetDesc,
                        emailLink: packetInfo.packetLink,
                        type: 'Packet'
                    })
                    //dispatch('avatarSpeak', ansObj.answer);
                    break;

                case "showimage":
                    console.log('Inside Intent Show Image')
                    dispatch('avatarSpeak', ansObj.answer);
                    break;

                case "showhours":
                    dispatch('openCourtTimingViewer', {})
                    dispatch('avatarSpeak', ansObj.answer);

                    break;

                case "session":
                    var action = ansObj.metadata.find(({ name }) => name === 'action').value
                    if (action === "still there") {
                        dispatch('kioskStillThere')
                    } else {
                        dispatch('resetKiosk')
                    }
                    break;

                case "followup":

                    var prompts = (ansObj.context).prompts
                    var followUp = [];
                    dispatch('avatarSpeak', ansObj.answer);
                    prompts.forEach(item => followUp.push(item));
                    dispatch('openOptionsViewer', {
                        dialogHeader: 'Tap on the below, or click on the microphone to ask another question',
                        dialogData: followUp,
                    })
                    break;
                default:
                    dispatch('openImageViewer', {
                        url: config.url.clerkOffice
                    })
                    dispatch('avatarSpeak', 'Sorry I’m still learning. Please ask me a different question or, get information about this topic at the Clerk’s Office located here.')
                    break;
            }
            // ToDo - Have to complete the intent based answers.
        }

    }
}
