<template>
  <v-dialog
      v-model="getCourtTimingViewer.show"
      @click:outside="$store.commit('closeCourtTimingViewer')"
      width="40%"
      persistent>

    <v-card class="pa-5">
      <div style="position: absolute; align-self: end; right: 0; top: 0">
        <v-btn
            icon
            @click="$store.commit('closeCourtTimingViewer')"
            class="mr-5 mt-3">
          <v-icon color="red" x-large>mdi-window-close</v-icon>
        </v-btn>
      </div>
      <span
          class="text-h4 text--black">
          Chaves County Courthouse
        </span>
      <br/>
      <span>
          Monday through Friday
        </span>
      <br>
      <span>
          8 AM - 12 Noon
      </span>
      <br>
      <span>
          1 PM - 5 PM
      </span>
      <br>
      <span>
          NO financial transactions after 4:30pm
      </span>
      <br/>
      <br>
      <span
          class="text-h6">
          Physical Address
        </span>
      <br>
      <span>
          Fifth Judicial District Court <br> 400 N. Virginia <br> Roswell, NM 88201
        </span>
      <br>
      <br>
      <span
          class="text-h6">
          Mailing Address
        </span>
      <br>
      <span>
          P.O. Box 1776 <br> Roswell, NM 88202-1776
        </span>
      <br>
      <br>
      <span
          class="text-h6">
        Contact
      </span>
      <br>
      <span>
        Roswell District Clerk: 575-622-2212 <br> Roswell District Clerk Fax: 575-624-9510
      </span>

    </v-card>
  </v-dialog>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: "courtTimingViewer",
  computed: {
    ...mapGetters(['getCourtTimingViewer'])
  }
}
</script>

<style scoped>

</style>
