<template>
    <v-row
            no-gutters
            class="fill-height">
        <v-col cols="6">
            <avatar :initialWidth="avatarWidth"></avatar>
        </v-col>
        <v-col cols="6">
            <menuMatrix></menuMatrix>
        </v-col>
    </v-row>
</template>

<script>
    import avatar from '@/components/avatar.vue'
    import menuMatrix from '@/components/menuMatrix.vue'
    import {mapGetters} from 'vuex';

    export default {
      name: 'Home',
      components: {
        avatar,
        menuMatrix
      },
      computed: {
        ...mapGetters(['appDimensions']),
        avatarWidth(){
          return this.appDimensions.width / 2
        }
      }
    }
</script>

