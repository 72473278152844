<template>
    <div>
        <v-row class="mt-n7" no-gutters align="center" justify="center">
            <v-col cols="6">
                <v-card flat>
                    <v-card-text class="d-flex justify-center">
                        <v-text-field :placeholder="$i18n.t('placeHolder')" class="text-h5" hide-details outlined
                            height="55px" rounded v-model="searchKeyword" @click="expand = !expand"
                            :append-icon="searchBarIcon" @click:append="searchKeyword = ''">
                        </v-text-field>
                    </v-card-text>
                    <v-expand-transition>
                        <v-card-text class="d-flex justify-center ma-0 pa-0" v-if="expand">
                            <SimpleKeyboard class="keyboard" @onKeyPress="onKeyPress" @onChange="onChange">
                            </SimpleKeyboard>
                        </v-card-text>
                    </v-expand-transition>
                </v-card>
            </v-col>

            <v-col cols="12" align="center" v-if="expand || searchKeyword !== ''">
                <v-card v-if="expand || searchKeyword !== ''" @click="expand = false" :ripple="false"
                    class="mt-2 containerBorder overflow-y-auto" width="95%" flat :height="cardHeight">
                    <!-- No keyword matched -->
                    <v-card-text v-if="matchingSrlGuides.length === 0 && matchingFaqs.length === 0">
                        <span class="text-h4">
                            No matching Question/Keyword found.
                        </span>
                    </v-card-text>

                    <!-- Keyword matched, displaying the matched FAQs -->

                    <v-card-text v-else>
                        <v-expansion-panels class="px-6" focusable dark>
                            <v-expansion-panel v-for="(item, index) in matchingFaqs" class="mt-2" :key="index">
                                <v-expansion-panel-header color="primary" class="white--text text-h6"
                                    @click="clickedFaq(item.question)">
                                    <template v-slot:actions>
                                        <v-icon color="white" size="35">mdi-arrow-down-bold-circle</v-icon>
                                    </template>
                                    {{ item.question }}
                                </v-expansion-panel-header>
                                <v-expansion-panel-content class="black--text px-2 pt-2 text-justify text-h6"
                                    color="white">
                                    <p v-html="item.answer"></p>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>

                        <!-- Keyword matched, displaying the matched SRL Guides -->

                        <v-row class="pa-5" no-gutters>
                            <v-col v-for="item in matchingSrlGuides" :key="item.id"
                                @click="viewDoc(item.guideUrl, item.guideName, item.guideLink, 'Guide')" class="pa-3"
                                cols="4">
                                <v-card height="80" flat class="formfolder" dark color="primary">
                                    <v-row no-gutters class="fill-height" align="center">
                                        <v-col cols="2">
                                            <v-icon size="45" color="primary lighten-4">
                                                mdi-folder-table-outline
                                            </v-icon>
                                        </v-col>
                                        <v-col cols="10" align="start">
                                            <span class="headline">
                                                {{ item.guideName }}
                                            </span>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-col>
                        </v-row>

                    </v-card-text>
                </v-card>
            </v-col>

            <!-- Default Display when the search bar is closed -->
            <v-col cols="12" v-else>
                <v-row no-gutters>
                    <v-col cols="3" class="mt-5" align="center">
                        <v-card width="95%" class="containerBorder" :height="cardHeight">
                            <v-list class="overflow-y-auto">
                                <v-list-item v-for="(cat, i) in $t('faqCategory')" :key="i">
                                    <v-list-item-content>
                                        <v-btn height="45"
                                            @click="$store.commit('setSelectedFaqCat', { name: cat.categoryName, type: cat.displayType })"
                                            v-bind:color="getSelectedFaqCat.name.toLowerCase() === cat.categoryName.toLowerCase() ? '#15306B' : 'primary'"
                                            class="white--text">{{
                                                cat.categoryName
                                            }}</v-btn>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-card>
                    </v-col>
                    <v-col cols="9" class="mt-5" align="center">
                        <v-card width="95%" class="containerBorder overflow-y-auto" :height="cardHeight">
                            <div v-if="getSelectedFaqCat.type === 'expansionPanel'">
                                <v-expansion-panels focusable dark class="px-3">
                                    <v-expansion-panel v-for="(item, i) in filteredFaqs()" :key="i" class="mt-2">
                                        <v-expansion-panel-header color="primary" class="white--text text-h6">
                                            <template v-slot:actions>
                                                <v-icon color="white" size="35">mdi-arrow-down-bold-circle</v-icon>
                                            </template>
                                            {{ item.question }}
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content color="white"
                                            class="black--text pt-4 text-justify text-h6">
                                            <v-row class="fill-height" no-gutters>
                                                <v-col cols="10">
                                                    <span v-html="item.answer"></span>
                                                </v-col>
                                                <v-col cols="2" align="center" v-if="item.showMap">
                                                    <div v-for="(map, i) in assignedMaps(item.mapName)" :key="i">
                                                        <v-btn tile color="secondary" @click="showMap(map)"
                                                            class="my-3">
                                                            <v-icon left dark medium> mdi-map-marker-radius </v-icon>
                                                            {{ map }}
                                                        </v-btn>
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                            </div>
                            <div v-else>
                                <v-row class="ma-3">
                                    <v-col cols="4" v-for="(item, i) in filteredFaqs()" :key="i">
                                        <v-card class="formfolder" dark color="primary" height="80" flat
                                            @click="viewDoc(item)">
                                            <v-row no-gutters class="fill-height" align="center">
                                                <v-col cols="2">
                                                    <v-icon size="45" color="primary lighten-4">
                                                        mdi-folder-table-outline
                                                    </v-icon>
                                                </v-col>
                                                <v-col cols="10" align="start">
                                                    <span class="headline">
                                                        {{ item.guideName | trimLength }}
                                                    </span>
                                                </v-col>
                                            </v-row>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>

<script>

import { mapGetters } from 'vuex';
import SimpleKeyboard from "@/components/SimpleKeyboard";


export default {
    name: 'faqs',
    components: {
        SimpleKeyboard,
    },
    data() {
        return {
            tab: 0,
            input: '',
            searchKeyword: '',
            searchBarIcon: 'mdi-magnify',
            expand: false,
            cardHeight: 650,
            displayType: 'expansionPanel'
        }
    },
    computed: {
        ...mapGetters(['getQnaKeyword', 'getSelectedFaqCat']),

        // Returns matching FAQs based on searched keyword
        matchingFaqs() {
            let temp = this.$i18n.t("faqs").filter(faq => faq.category !== "SRL Guide")
            return temp.filter(faq => faq.question.toLowerCase().includes(this.searchKeyword.toLowerCase()))
        },

        // Returns matching SRL Guide based on searched keyword
        matchingSrlGuides() {
            let temp = this.$i18n.t("faqs").filter(faq => faq.category === "SRL Guide")
            return temp.filter(faq => faq.guideName.toLowerCase().includes(this.searchKeyword.toLowerCase()))
        },
    },
    watch: {
        expand(newVal) {
            if (newVal) {
                this.searchBarIcon = 'mdi-close-circle'
                this.cardHeight = 380;
            } else {
                this.searchBarIcon = 'mdi-magnify'
                this.cardHeight = 650
            }
        },
        getQnaKeyword(newVal) {
            this.searchKeyword = newVal
        },
    },
    methods: {
        onChange(input) {
            this.searchKeyword = input;
        },
        onKeyPress(button) {
            if (button === "{bksp}")
                this.searchKeyword = this.searchKeyword.slice(0, -1);
        },
        filteredFaqs() {
            return this.$i18n.t("faqs").filter(faq => faq.category.toLowerCase().includes(this.getSelectedFaqCat.name.toLowerCase()))
        },
        viewDoc(guide) {

            // Display the SRL Guide for reference to the user 
            // PDF viewer will give 2 options, pick up or email to self
            this.$store.dispatch('openPdfViewer', {
                url: guide.guideUrl,
                type: 'guide',
                name: guide.guideName,
                emailLink: guide.guideLink
            })

            // Tracking Touch by the user
            this.$store.commit('setTouchRequest', {
                module: 'Frequently Asked Question',
                action: 'Show SRL Guide',
                response: guide.guideName,
                timeStamp: new Date(),
                requestType: 'Touch'
            })
        },
        // The below method tracks touched faqs by the user
        clickedFaq(question) {
            this.$store.commit('setTouchRequest', {
                module: 'Frequently Asked Question',
                action: 'Show Faq',
                response: question,
                timeStamp: new Date(),
                requestType: 'Touch'
            })
        }
    },
    filters: {
        // for a good UI experience.
        trimLength(val) {
            if (val.length < 90) { return val }
            return `${val.substring(0, 70)}...`
        }
    },
}
</script>
<i18n>
  {
    "en": {
        "placeHolder": "Enter a keyword to search for FAQ"
      },
    "es":{
        "placeHolder": "Introduzca una palabra clave para buscar preguntas frecuentes"
      }
  }
</i18n>