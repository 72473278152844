import { ApolloClient } from 'apollo-client';
import axios from 'axios';
import { createHttpLink, HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import * as fb from "@/firebase/index.js";
import {
    floorQuery,
    allFormQuery,
    allFormSecondQuery,
    allFormThirdQuery,
    mapQuery,
    faqQuery,
    faqCategoryQuery,
    formCategoryQuery,
    formPacketQuery,
    homepageQuery
} from '@/graphql/query';

function today() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = today.getFullYear();
    return (mm + '-' + dd + '-' + yyyy);
}

export default {
    state: {
        dataLoaded: false,
        apolloClient: undefined,
        homepage: [],
        floors: [],
        maps: new Map(),
        forms: new Map(),
        faqs: [],
        faqCategory: [],
        formCategories: [],
        formTypes: [],
        formPackets: [],
        fileCache: null,
        count: 0,
        modules: [],
        languages: [{
            name: "English",
            code: "en",
            load: true
        }],
        kioskLocation: ''
    },
    getters: {
        getDataLoaded: state => state.dataLoaded,
        getModules: state => state.modules,
        getHomepage: state => state.homepage,
        getFloors: state => state.floors,
        getMaps: state => state.maps,
        getFaqs: state => state.faqs,
        getFaqCategories: state => state.faqCategory,
        getForms: state => state.forms,
        getFormCategory: state => state.formCategories,
        getFormType: state => state.formTypes,
        getFormPackets: state => state.formPackets,
        getCourtType: state => state.courtType,
        getLanguages: state => state.languages,
        getKioskLocation: state => state.kioskLocation
    },
    mutations: {
        setDataLoaded(state, status) {
            state.dataLoaded = status;
        },
        setApolloClient(state, uri) {
            const fragmentMatcher = new IntrospectionFragmentMatcher({
                introspectionQueryResultData: {
                    __schema: {
                        types: [],
                    },
                },
            });
            state.apolloClient = new ApolloClient({
                link: new HttpLink({ uri: uri }),
                cache: new InMemoryCache({ fragmentMatcher })
            })
        },
        setModules(state, module) {
            state.modules.push(module)
        },
        setLanguages(state, lang) {
            state.languages.push(lang)
        },
        setkioskLocation(state, location) {
            state.kioskLocation = location
        },
        setHomepage(state, page) {
            console.log('Homepage: ', page)
            state.homepage.push(page)
        },
        setFloors(state, floor) {
            state.floors.push(floor)
        },
        setMaps(state, map) {
            state.maps.set(map.mapName.toLowerCase(), map)
        },
        setForms(state, form) {

            state.forms.set(form.id, form)
            //state.forms.set(form.formName.toLowerCase(), form)
        },
        setFaqs(state, faq) {
            state.faqs.push(faq)
        },
        setFaqCategory(state, category) {
            state.faqCategory.push(category)
        },
        setFormCategories(state, category) {
            state.formCategories.push(category)
        },
        setFormTypes(state, types) {
            state.formTypes = types
        },
        setFormPackets(state, packet) {
            state.formPackets.push(packet)
        },

    },
    actions: {
        createApolloConnection({ commit }, uri) {
            return new Promise((resolve, reject) => {
                if (uri === '' || uri === null) reject('Empty or Invalid URI')
                try {
                    commit('setApolloClient', uri)
                    resolve('Apollo linked successfully');
                } catch (err) {
                    reject(err.message);
                }
            })
        },
        initiateDataPull({ commit, dispatch }, kioskData) {
            commit('setkioskLocation', kioskData.data().locationCode)
            dispatch('indentifyModuleLang', {
                module: kioskData.data().modules,
                language: kioskData.data().languages
            })
            //dispatch('updateAutoReferesh', kioskData)
        },
        indentifyModuleLang({ commit, dispatch }, data) {
            data.module.forEach(mod => {
                if (mod.load) {
                    commit('setModules', mod.alias)
                }
            })
            data.language.forEach(lang => {
                commit('setLanguages', lang)
            })
            dispatch('loadAvatar')
        },
        fetchHomePage({ commit, dispatch, state, getters }) {
            return new Promise((resolve, reject) => {
                
                getters.getLanguages.forEach(lang => {
                    if (lang.load) {
                        console.log('Languages: ', getters.getLanguages, getters.getModules)
                        state.apolloClient.query({
                            query: homepageQuery,
                            variables: {
                                "alias": getters.getModules,
                                "lang": lang.code
                            }
                        }).then(items => {
                            items.data.homepages.forEach(page => {
                                dispatch('saveInCache', page.menuIcon.url)
                                    .then(response => {
                                        page.menuIcon.url = response
                                        commit('setHomepage', page);
                                    })
                            })
                            resolve('Homepage fetched successfully.')

                        }, error => {
                            reject(error.message)
                        })
                    }
                })
            })
        },
        // Fetch Floor from strapi
        fetchFloors({ commit, dispatch, state, getters }) {
            return new Promise((resolve, reject) => {
                getters.getLanguages.forEach(lang => {
                    if (lang.load) {
                        state.apolloClient.query({
                            query: floorQuery,
                            variables: {
                                "lang": lang.code
                            }
                        }).then(floors => {
                            floors.data.floors.forEach(floor => {
                                dispatch('saveInCache', floor.floorImage.url)
                                    .then(response => {
                                        floor.floorImage.url = response;
                                        commit('setFloors', floor)
                                    })
                            })
                            resolve('Floor data fetched successfully.')
                        }, error => {
                            reject(error.message)
                        })
                    }
                })
            })
        },

        // Fetching Maps from Strapi
        fetchMaps({ commit, state, dispatch, getters }) {
            return new Promise((resolve, reject) => {
                getters.getLanguages.forEach(lang => {
                    if (lang.load) {
                        state.apolloClient.query({
                            query: mapQuery,
                            variables: {
                                "lang": lang.code
                            }
                        }).then(maps => {
                            maps.data.maps.forEach(map => {
                                dispatch('saveInCache', map.mapImage.url)
                                    .then(response => {
                                        commit('setMaps', {
                                            mapName: map.mapName,
                                            mapFloor: map.mapFloor,
                                            mapImage: response,
                                            speech: map.speech,
                                            locale: map.locale
                                        })
                                    })
                            })
                            resolve('Map data fetched successfully.')
                        }, error => {
                            reject(error.message)
                        })
                    }
                })
            })
        },


        // --------------------------------------------------------- Fetch Data related to Faqs Page -----------------------------------------

        fetchFaqs({ commit, state, getters, dispatch }) {
            return new Promise((resolve, reject) => {
                getters.getLanguages.forEach(lang => {
                    if (lang.load) {
                        state.apolloClient.query({
                            query: faqQuery,
                            variables: {
                                "lang": lang.code
                            }
                        }).then(faqs => {
                            //commit('setFaqs', faqs.data.faqs);
                            faqs.data.faqs.forEach(faq => {
                                console.log('Faqs: ', faq)
                                if (faq.faqType[0].__typename === "ComponentFaqDisplayExpansionPanel") {
                                    let data = {
                                        question: faq.faqType[0].question,
                                        answer: faq.faqType[0].answer.replace(/\n/g, '<br />'),
                                        category: faq.category,
                                        displayType: 'panel',
                                        locale: faq.locale
                                    }
                                    commit('setFaqs', data)
                                } else {
                                    dispatch('saveInCache', faq.faqType[0].guide.url)
                                        .then(response => {
                                            let data = {
                                                guideName: faq.faqType[0].guideName,
                                                guideLink: faq.faqType[0].guideLink,
                                                guideUrl: response,
                                                category: faq.category,
                                                displayType: 'button',
                                                locale: faq.locale
                                            }
                                            commit('setFaqs', data)
                                        })
                                }
                            })
                            resolve('FAQs fetched successfully.')
                        }, error => {
                            reject(error.message)
                        })
                    }
                })
            })
        },

        fetchFaqCategory({ commit, state, getters }) {
            return new Promise((response, reject) => {
                getters.getLanguages.forEach(lang => {
                    if (lang.load) {
                        state.apolloClient.query({
                            query: faqCategoryQuery,
                            variables: {
                                "lang": lang.code
                            }
                        }).then(faqCategory => {
                            faqCategory.data.faqCategories.forEach(category => {
                                commit('setFaqCategory', category)
                            })

                            response('FAQ categories fetched successfully')
                        }, error => {
                            reject(error.message)
                        })
                    }
                })
            })
        },

        // --------------------------------------------------------- Fetch Data related to Forms Page -----------------------------------------

        fetchFormCategories({ commit, state, getters }) {
            return new Promise((response, reject) => {
                getters.getLanguages.forEach(lang => {
                    if (lang.load) {
                        state.apolloClient.query({
                            query: formCategoryQuery,
                            variables: {
                                "lang": lang.code
                            }
                        }).then(categories => {
                            categories.data.formCategories.forEach(category => {
                                commit('setFormCategories', category)
                            })
                            response('Form categories fetched successfully.')
                        }, error => {
                            reject(error.message)
                        })
                    }
                })
            })
        },


        fetchForms({ commit, dispatch, state, getters }) {
            return new Promise((resolve, reject) => {
                getters.getLanguages.forEach(lang => {
                    if (lang.load) {
                        state.apolloClient.query({
                            query: allFormQuery,
                            variables: {
                                "lang": lang.code
                            }
                        }).then(forms => {
                            forms.data.allForms.forEach(form => {
                                dispatch('saveInCache', form.formPdf.url)
                                    .then(response => {
                                        commit('setForms', {
                                            id: form.id,
                                            formName: form.formName,
                                            formNumber: form.formNumber,
                                            formCategory: form.formCategory,
                                            displayIcon: form.displayIcon,
                                            formPdf: response,
                                            pdfUrl: form.pdfUrl,
                                            locale: form.locale,
                                            court: form.court
                                        })
                                    })
                            })
                            //resolve('Form data fetched successfully.')
                        }, error => {
                            reject(error.message)
                        })

                        state.apolloClient.query({
                            query: allFormSecondQuery,
                            variables: {
                                "lang": lang.code
                            }
                        }).then(forms => {
                            forms.data.allForms.forEach(form => {
                                dispatch('saveInCache', form.formPdf.url)
                                    .then(response => {
                                        commit('setForms', {
                                            id: form.id,
                                            formName: form.formName,
                                            formNumber: form.formNumber,
                                            formCategory: form.formCategory,
                                            displayIcon: form.displayIcon,
                                            formPdf: response,
                                            pdfUrl: form.pdfUrl,
                                            locale: form.locale,
                                            court: form.court
                                        })
                                    })
                            })

                            //resolve('Form data fetched successfully.')
                        }, error => {
                            reject(error.message)
                        })

                        state.apolloClient.query({
                            query: allFormThirdQuery,
                            variables: {
                                "lang": lang.code
                            }
                        }).then(forms => {
                            forms.data.allForms.forEach(form => {
                                dispatch('saveInCache', form.formPdf.url)
                                    .then(response => {
                                        commit('setForms', {
                                            id: form.id,
                                            formName: form.formName,
                                            formNumber: form.formNumber,
                                            formCategory: form.formCategory,
                                            displayIcon: form.displayIcon,
                                            formPdf: response,
                                            pdfUrl: form.pdfUrl,
                                            locale: form.locale,
                                            court: form.court
                                        })
                                    })
                            })

                            resolve('Form data fetched successfully.')
                        }, error => {
                            reject(error.message)
                        })
                    }
                })
            })
        },

        fetchFormPackets({ commit, dispatch, state }) {
            return new Promise((response, reject) => {
                state.apolloClient.query({
                    query: formPacketQuery,
                    variables: {
                        "lang": 'en'
                    }
                }).then(packets => {
                    packets.data.formPackets.forEach(packet => {
                        let files = [];
                        packet.packetFiles.forEach(file => {
                            dispatch('saveInCache', file.url)
                                .then(response => {
                                    files.push(response)
                                })
                        })
                        commit('setFormPackets', {
                            packetName: packet.packetName,
                            packetDesc: packet.packetDesc,
                            packetLink: packet.packetLink,
                            packetFiles: files
                        })
                    })
                    response('Form packets fetched successfully.')
                }, error => {
                    reject(error.message)
                })
            })
        },


        // --------------------------------- Data Caching (Image, PDF) ------------------------------------------------------------
        saveInCache({ state, getters }, path) {
            return new Promise((response, reject) => {
                state.fileCache = caches.open('fileCache')
                    .then(cache => {
                        cache.match(getters.getCMSlink + path)
                            .then(cacheResponse => {
                                if (cacheResponse) {
                                    return (cacheResponse.blob())
                                        .then(blob => {
                                            response(URL.createObjectURL(blob))
                                        })
                                } else {
                                    cache.add(getters.getCMSlink + path)
                                    cache.match(getters.getCMSlink + path)
                                        .then(cacheResponse => {
                                            return (cacheResponse.blob())
                                                .then(blob => {
                                                    response(URL.createObjectURL(blob))
                                                })
                                        })
                                }
                            })
                    })
            })
        },

        // ---------------------------------------------- Odessey --------------------------------------------------------------

        searchOdysseybyName({ state }, keyword) {
            return new Promise((response, reject) => {
                fb.odysseyCollection
                    .where("courtLocation", "==", "roswell")
                    .where("dateCreated", "==", today())
                    .where("partyOneName", "==", keyword.toLowerCase())
                    .get()
                    .then(querySnapshot => {
                        if (querySnapshot.empty) reject('Sorry I did not find any results for this user name.')
                        response(querySnapshot);
                    })
            })
        },
        searchOdysseybyCaseNo({ state }, caseNumber) {
            return new Promise((response, reject) => {
                fb.odysseyCollection
                    .where("courtLocation", "==", "roswell")
                    .where("dateCreated", "==", today())
                    .where("caseNo", "==", caseNumber)
                    .get()
                    .then(querySnapshot => {
                        if (querySnapshot.empty) reject('Sorry I did not find any results for this case number.')
                        response(querySnapshot);
                    })

            })
        },
        searchByCaseNo({ state }, caseNumber) {
            return new Promise((response, reject) => {
                let config = {
                    method: 'get',
                    url: 'https://us-central1-connect-dashboard.cloudfunctions.net/apiV2/caseSearch/a763cd056f1b2405788443b7197e0708',
                    params: {
                        caseNumber: caseNumber,
                        courtName: 'fifth judicial district chaves county'
                    }
                };

                axios(config)
                    .then(result => {
                        //   console.log('DATA X CHANGE: ', result.data)
                        response(result.data);
                    })
                    .catch(function (error) {
                        // console.log(error);
                        reject(error)
                    });

            })
        },
        searchByName({ state }, nameObject) {
            return new Promise((response, reject) => {
                let parameter;
                if (nameObject.middleName === '' || nameObject.middleName === null) {
                    parameter = {
                        firstName: (nameObject.firstName.toLowerCase()).trim(),
                        lastName: (nameObject.lastName.toLowerCase()).trim(),
                        courtName: 'fifth judicial district chaves county'
                    }
                }
                else {
                    parameter = {
                        firstName: (nameObject.firstName.toLowerCase()).trim(),
                        lastName: (nameObject.lastName.toLowerCase()).trim(),
                        middleName: (nameObject.middleName.toLowerCase()).trim(),
                        courtName: 'fifth judicial district chaves county'
                    }
                }
                let config = {
                    method: 'get',
                    url: 'https://us-central1-connect-dashboard.cloudfunctions.net/apiV2/caseSearch/a763cd056f1b2405788443b7197e0708',
                    params: parameter
                };

                axios(config)
                    .then(result => {
                        response(result.data);
                    })
                    .catch(function (error) {
                        // console.log(error);
                        reject(error)
                    });

            })
        }
    }
}
