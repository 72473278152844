<template>
  <v-row no-gutters align="start" class="mb-n15">
    <v-col cols="2">
      <v-list dark color="primary" flat>
        <span class="text-h4 d-flex justify-center pa-2">
          Map Directory
        </span>
        <v-list-item-group class="mt-2" color="white">
          <v-virtual-scroll class="scrollClass" bench="0" :items="maps" item-height="64" height="715">
            <template v-slot:default="{ item }">
              <v-divider color="white"></v-divider>
              <v-list-item @click="selectImage(item.mapImage, item.mapFloor, item.speech, item.mapName)">
                <v-list-item-content>
                  <span class="white--text text-h6 font-weight-regular">
                    {{ item.mapName | trimLength }}
                  </span>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-virtual-scroll>
        </v-list-item-group>
      </v-list>
    </v-col>
    <v-col cols="10">
      <v-tabs active-class="tabClass" background-color="primary" centered height="70" dark v-model="active_tab">
        <v-tab v-for="(floor, index) in $t('floors')" :key="index"
          @click="selectImage(floor.floorImage.url, floor.floorNum, floor.speech, floor.floorName)"
          class="text-h6 pa-5">
          {{ floor.floorName }}
        </v-tab>
      </v-tabs>
      <v-row>
        <v-col align="center">
          <v-img :src="url" width="65%" contain>
          </v-img>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'maps',
  data() {
    return {
      active_tab: 0,
      url: '',
    }
  },
  computed: {
    ...mapGetters(['getFloors', 'getMaps', 'getCMSlink']),
    maps() {
      let temp = []
      this.$i18n.t('maps').forEach(map => {
        temp.push(map)
      })
      return temp
    }
  },
  methods: {
    selectImage(mapPath, mapFloor, speech, mapName) {
      this.active_tab = mapFloor - 1;
      this.url = mapPath
      if (speech !== undefined) {
        this.$store.dispatch('avatarSpeak', speech)
        // Touch Usage
        this.$store.commit('setTouchRequest', {
          module: 'Maps and Directory',
          action: 'View Map',
          response: mapName,
          timeStamp: new Date(),
          requestType: 'Touch'
        })
      }
    },
  },
  filters: {
    trimLength(val) {
      if (val.length < 25) {
        return val
      }
      return `${val.substring(0, 23)}...`
    }
  },
  mounted() {
    this.selectImage(this.getFloors[0].floorImage.url, this.getFloors.floorNum)
  }

}
</script>
<style>
.tabClass {
  background-color: #15306B;
  color: white !important;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
</style>