
module.exports = {
  firebase:{
    apiKey: "AIzaSyDXIYfvYetg0yWR7irs_Tn-8WgHpRUv4os",
    authDomain: "nm-dashboard.firebaseapp.com",
    databaseURL: "https://nm-dashboard-default-rtdb.firebaseio.com",
    projectId: "nm-dashboard",
    storageBucket: "nm-dashboard.appspot.com",
    messagingSenderId: "638596521385",
    appId: "1:638596521385:web:3a12181dc87ea717d438b8"
  },
  url:{
    clerkOffice: 'https://cms.d5roswell.arsconnect.com/uploads/1_district_court_clerks_office_4f6071543f.gif'
  }
}

