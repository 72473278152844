<template>
  <div>
    <v-row class="mt-3" no-gutters align="center" justify="center">
      <v-col cols="6">
        <v-card flat>
          <v-card-text class="d-flex justify-center pa-0">
            <v-text-field :placeholder="$i18n.t('placeHolder')" class="text-h5" hide-details outlined height="55px"
              rounded v-model="searchKeyword" @click="expand = !expand" :append-icon="searchBarIcon"
              clear-icon="mdi-close-circle" @click:append="searchKeyword = ''; expand = false">
            </v-text-field>
          </v-card-text>
          <v-expand-transition>
            <v-card-text class="d-flex justify-center ma-0 pa-0" v-if="expand">
              <SimpleKeyboard class="keyboard" @onKeyPress="onKeyPress" @onChange="onChange">
              </SimpleKeyboard>
            </v-card-text>
          </v-expand-transition>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-row no-gutters class="ma-2">
          <v-col cols="12" align="center" v-if="expand || searchKeyword !== ''">
            <v-card v-if="expand || searchKeyword !== ''" :ripple="false" class="mt-2 containerBorder overflow-y-auto"
              width="95%" flat :height="cardHeight">
              <v-card-text v-if="matchingForms.length === 0" class="pa-0">
                <span class="text-h4"> No matching forms found. </span>
              </v-card-text>
              <div v-else>
                <v-row no-gutters class="mt-6 ml-6">
                  <v-col cols="4" v-for="(form, i) in matchingForms" :key="i">
                    <v-card class="mt-2 mb-2 mx-1 PDFborder" flat @click="viewDoc(form, 'form')" width="90%">
                      <v-row no-gutters class="fill-height" align="center">
                        <v-col cols="2" align="center">
                          <v-img src="@/assets/pdf.png" contain height="60"></v-img>
                        </v-col>
                        <v-col cols="10">
                          <v-card-title class="text-subtitle-1 font-weight-bold justify-center">
                            {{ form.formNumber }}
                          </v-card-title>
                          <v-card-text class="text-subtitle-1 font-weight-bold justify-center">
                            {{ form.formName | trimLength }}
                          </v-card-text>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
              </div>
            </v-card>
          </v-col>

          <v-col cols="12" v-else>
            <v-row>
              <v-col cols="3" class="mt-5" align="center">
                <v-card width="95%" class="containerBorder pa-0 ma-0" :height="cardHeight" color="primary">

                  <v-btn height="45" width="90%" class="my-3"
                    @click="$store.commit('setSelectedFormCat', { category: 'Packets', showTab: false })"
                    v-bind:color="getSelectedFormCat.category === 'Packets' ? '#15306B' : 'white'"
                    v-bind:style="{ 'color': getSelectedFormCat.category === 'Packets' ? 'white' : 'black' }">{{
                      $t('packets')
                    }}
                  </v-btn>

                  <!-- The below is used to display the English Form. The below button is only visible in Spanish Mode.  -->

                  <v-btn height="45" width="90%" v-if="getCurrentLanguage === 'es-US'"
                    @click="$store.commit('setSelectedFormCat', { category: 'English', showTab: false })" class="mb-3"
                    v-bind:color="getSelectedFormCat.category === 'English' ? '#15306B' : 'white'"
                    v-bind:style="{ 'color': getSelectedFormCat.category === 'English' ? 'white' : 'black' }">
                    {{ $t('englishForms') }}</v-btn>

                  <v-btn height="45" width="90%" v-else
                    @click="$store.commit('setSelectedFormCat', { category: 'Bilingual', showTab: false })" class="mb-3"
                    v-bind:color="getSelectedFormCat.category === 'Bilingual' ? '#15306B' : 'white'"
                    v-bind:style="{ 'color': getSelectedFormCat.category === 'Bilingual' ? 'white' : 'black' }">
                    {{ $t('spanishForms') }}</v-btn>

                  <!-- The below is used to display the All Form. It lists all the forms.  -->

                  <v-btn height="45" width="90%"
                    @click="$store.commit('setSelectedFormCat', { category: 'All', showTab: false })" class="mb-3"
                    v-bind:color="getSelectedFormCat.category === 'All' ? '#15306B' : 'white'"
                    v-bind:style="{ 'color': getSelectedFormCat.category === 'All' ? 'white' : 'black' }">{{
                      $t('allForms')
                    }}</v-btn>

                  <v-list class="overflow-y-auto" :height="getFormCatListHeight">
                    <v-list-item v-for="(cat, i) in $t('formCategory')" :key="i">
                      <v-list-item-content>
                        <v-btn height="45"
                          @click="$store.commit('setSelectedFormCat', { category: cat.categoryType, showTab: true });"
                          v-bind:color="getSelectedFormCat.category.toLowerCase() === cat.categoryType.toLowerCase() ? '#15306B' : 'primary'"
                          class="white--text">{{
                            cat.categoryName
                          }}</v-btn>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-col>

              <v-col cols="9" class="mt-5" align="center">
                <v-card width="95%" class="containerBorder" :height="cardHeight" v-if="getSelectedFormCat.showTab">
                  <v-tabs v-model="tab" active-class="tabClass">
                    <v-tab v-for="(categoryType, i) in getCategoryType" :key="i"> {{ categoryType.court }} Court
                    </v-tab>
                  </v-tabs>
                  <v-tabs-items v-model="tab">
                    <v-tab-item v-for="(categoryType, i) in getCategoryType" :key="i">
                      <v-card class="overflow-y-auto" flat :height="cardHeight - 60">
                        <v-row no-gutters class="mt-6 ml-6">
                           <v-col cols="4" v-for="(form, i) in getSelectedForms(categoryType.court)" :key="i">
                            <v-card class="mt-2 mb-2 mx-1 PDFborder" flat @click="viewDoc(form, 'form')" width="90%">
                              <v-row no-gutters class="fill-height" align="center">
                                <v-col cols="2" align="center">
                                  <v-img src="@/assets/pdf.png" contain height="60"></v-img>
                                </v-col>
                                <v-col cols="10" align-self="center" class="pt-3">
                                  <v-card-title class="text-subtitle-1 font-weight-bold justify-center pa-0 ma-0">
                                    {{ form.formNumber }}
                                  </v-card-title>
                                  <v-card-text class="text-subtitle-1 font-weight-bold justify-center">
                                    {{ form.formName | trimLength }}
                                  </v-card-text>
                                </v-col>
                              </v-row>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-tab-item>
                  </v-tabs-items>
                </v-card>
                <v-card v-else width="95%" class="containerBorder" :height="cardHeight">
                  <v-card class="overflow-y-auto" flat :height="cardHeight - 10">
                    <v-row no-gutters class="mt-6 ml-6" v-if="getSelectedFormCat.category !== 'Packets'">
                      <v-col cols="4" v-for="(form, i) in getSelectedForms('NA')" :key="i">
                        <v-card class="mt-2 mb-2 mx-1 PDFborder" flat @click="viewDoc(form, 'form')" width="90%">
                          <v-row no-gutters class="fill-height" align="center">
                            <v-col cols="2" align="center">
                              <v-img src="@/assets/pdf.png" contain height="60"></v-img>
                            </v-col>
                            <v-col cols="10" align-self="center" class="pt-3">
                              <v-card-title class="text-subtitle-1 font-weight-bold justify-center pa-0 ma-0">
                                {{ form.formNumber }}
                              </v-card-title>
                              <v-card-text class="text-subtitle-1 font-weight-bold justify-center">
                                {{ form.formName | trimLength }}
                              </v-card-text>
                            </v-col>
                          </v-row>

                        </v-card>
                      </v-col>
                    </v-row>
                    <!-- To display form Packets -->
                    <v-row no-gutters v-else class="mt-6 ml-6">
                      <v-col v-for="(item, i) in getSelectedForms('NA')" :key="i" cols="4">
                        <v-card class="mt-2 mb-2 mx-1 PDFborder" flat width="90%" height="80"
                          @click="viewDoc(item, 'packet')">
                          <v-row no-gutters class="fill-height" align="center">
                            <v-col cols="2">
                              <v-img src="@/assets/pdf.png" contain height="50"></v-img>
                            </v-col>
                            <v-col cols="10" align="start">
                              <span class="text-h6">
                                {{ item.packetName | trimLength }}
                              </span>
                            </v-col>
                          </v-row>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import SimpleKeyboard from "@/components/SimpleKeyboard";

export default {
  name: 'forms',
  data() {
    return {
      tab: 0,
      searchKeyword: '',
      searchBarIcon: 'mdi-magnify',
      expand: false,
      cardHeight: 650,
    }
  },
  components: {
    SimpleKeyboard
  },
  computed: {
    ...mapGetters(['getFormType', 'getQnaKeyword', 'getFormCatListHeight', 'getSelectedFormCat', 'getCurrentLanguage', 'getForms']),

    getCategoryType() {
      let temp = []
      this.$i18n.t('formCategory').forEach(cat => {
        console.log('Selected Category :', this.getSelectedFormCat.category)
        if (cat.categoryType === this.getSelectedFormCat.category) {
          temp = cat.type
        }
      })
      console.log('WHat is inside: ', temp)
      return temp;
    },
    matchingForms() {
      let temp = []
      this.$i18n.t('forms').forEach(form => {
        if (form.formName.toLowerCase().includes(this.searchKeyword.toLowerCase())) {
          temp.push(form)
        }
      })
      return temp
    }
  },
  watch: {
    expand(newVal) {
      if (newVal) {
        this.searchBarIcon = 'mdi-close-circle'
        this.cardHeight = 380;
      } else {
        this.searchBarIcon = 'mdi-magnify'
        this.cardHeight = 650
      }
    },
    getQnaKeyword(newVal) {
      this.tab = 0;
      if (this.getCourt === 'district court') {
        this.$store.commit('setCourt', 'District Court')
      } else {
        this.$store.commit('setCourt', 'Magistrate Court')
      }
      let types = this.getCourtType.get(this.getCourt)
      types.forEach(type => {
        if (type.categoryName.toLowerCase() === newVal) {
          this.displayFolderContent(type.categoryName, type.categoryType.toLowerCase())
        }
      })
    },
  },
  methods: {
    onChange(input) {
      this.searchKeyword = input;
    },
    onKeyPress(button) {
      if (button === "{bksp}")
        this.searchKeyword = this.searchKeyword.slice(0, -1);
    },
    viewDoc(doc, docType) {
      console.log('Clicked Document: ', doc)
      if (docType === 'packet') {
        this.$store.dispatch('openPdfViewer', {
          url: doc.packetFiles,
          name: doc.packetName,
          emailLink: doc.packetLink,
          type: docType
        })
      } else {
        this.$store.dispatch('openPdfViewer', {
          url: doc.formPdf,
          name: doc.formName,
          emailLink: doc.pdfUrl,
          type: docType
        })
      }


      // Touch Usage
      this.$store.commit('setTouchRequest', {
        module: 'Forms',
        action: 'Form',
        response: doc.formNumber + ' - ' + doc.formName,
        timeStamp: new Date(),
        requestType: 'Touch'
      })
    },
    getSelectedForms(court) {
      let temp = []
      console.log('Selected Form Category: ', this.getSelectedFormCat.category, court)
      if (this.getSelectedFormCat.category === 'All') {

        this.$i18n.t("forms").forEach(form => {
          temp.push(form)
        })

      } else if (this.getSelectedFormCat.category === 'Packets') {

        temp = this.$i18n.t("formPackets")

      }
      else if (this.getSelectedFormCat.category === 'English') {
        // This is special case, no interationalization used. English forms to be shown in spanish version
        this.$store.getters.getForms.forEach(form => {
          if (form.locale === 'en') {
            temp.push(form)
          }
        })
      } else if (this.getSelectedFormCat.category === 'Bilingual') {
        this.$store.getters.getForms.forEach(form => {
          if (form.locale === 'es-US') {
            temp.push(form)
          }
        })
      }
      else {
        console.log('Selected Court: ', court)
        this.$i18n.t("forms").forEach(form => {
          if (form.formCategory.toLowerCase().includes(this.getSelectedFormCat.category.toLowerCase())) {
            temp.push(form)
          }
        })
        temp = temp.filter(form => form.court === court)
      }
      return temp;
    },
  },
  filters: {
    trimLength(val) {
      if (val.length < 35) {
        return val
      }
      return `${val.substring(0, 30)}...`
    }
  },
}
</script>

<style>
.formfolder {
  border-radius: 15px !important;
}
</style>
<i18n>
  {
    "en": {
        "packets": "Form Packets",
        "englishForms": "English Forms",
        "allForms": "All Forms",
        "placeHolder": "Enter name/number to search for forms",
        "spanishForms": "Bilingual Forms"
      },
    "es":{
        "packets": "Paquetes de Formulario",
        "englishForms": "Formularios en inglés",
        "allForms": "Todas las Formas",
        "placeHolder": "Ingrese nombre/número para buscar formularios",
        "spanishForms": "Bilingual Forms"
      }
  }
</i18n>
<style>
.tabClass {
  background-color: #15306B;
  color: white !important;
}
</style>